import React from 'react';
import { GenericIconProps } from '../types';

export const YouTube: React.FC<GenericIconProps> = ({
  color = 'currentColor',
  opacity,
  className,
  size,
}) => {
  return (
    <svg
      width={size}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      opacity={opacity}
    >
      <path
        d="M15.32 1.72699C14.886 0.954992 14.415 0.812992 13.456 0.758992C12.498 0.693992 10.089 0.666992 8.002 0.666992C5.911 0.666992 3.501 0.693992 2.544 0.757992C1.587 0.812992 1.115 0.953992 0.677 1.72699C0.23 2.49799 0 3.82599 0 6.16399C0 6.16599 0 6.16699 0 6.16699C0 6.16899 0 6.16999 0 6.16999V6.17199C0 8.49999 0.23 9.83799 0.677 10.601C1.115 11.373 1.586 11.513 2.543 11.578C3.501 11.634 5.911 11.667 8.002 11.667C10.089 11.667 12.498 11.634 13.457 11.579C14.416 11.514 14.887 11.374 15.321 10.602C15.772 9.83899 16 8.50099 16 6.17299C16 6.17299 16 6.16999 16 6.16799C16 6.16799 16 6.16599 16 6.16499C16 3.82599 15.772 2.49799 15.32 1.72699ZM6 9.16699V3.16699L11 6.16699L6 9.16699Z"
        fill={color}
      />
    </svg>
  );
};
